import { getDatabase, ref, child, get } from "firebase/database";

export default {
  state: {
    products: [],
    product: {},
    category: []
  },
  mutations: {
    setProducts(state, date) {
      state.products = date
    },
    setProduct(state, date) {
      state.product = date
    },
    setCategory(state, date) {
      state.category = date
    },
  },
  actions: {
    async fetchProducts({ commit, dispatch }) {
      try {
        const date = await get(child(ref(getDatabase()), 'products'))
        commit('setProducts', date.val())
      } catch (e) {
        console.log(e.message)
      }
    },
    async fetchProduct({ commit }, id) {
      try {
        const date = await get(child(ref(getDatabase()), `products/${id - 1}`))
        commit('setProduct', date.val())
      } catch (e) {
        console.log(e.message)
      }
    },
    async fetchCategory({ commit }) {
      try {
        const date = await get(child(ref(getDatabase()), 'category'))
        commit('setCategory', date.val())
      } catch (e) {
        console.log(e.message)
      }
    },
  },
  getters: {
    getProducts(state) {
      return state.products
    },
    getProduct(state) {
      return state.product
    },
    getCategory(state) {
      return state.category
    },
  },
}