<template>
  <header class="header" :class="{ auth: auth }">
    <div class="header__items">
      <div class="container">
        <div class="header__wrapper">
          <div class="bar">
            <div class="bar__block" @click="changeMobileMenu">
              <div class="bar__block-item"></div>
              <div class="bar__block-item"></div>
              <div class="bar__block-item"></div>
            </div>

            <p class="bar__text">Меню</p>
          </div>

          <div class="header__right">
            <div class="header__right-items header__right-auth" v-if="auth">
              <div class="header__right-profile">
                <div class="header__right-name">
                  {{ user ? user.name : "" }}
                  <span>
                    <img src="@/assets/images/header/pirate-profile.png" alt="" />
                  </span>
                </div>

                <ul class="header__right-submenu">
                  <li class="btn">
                    <router-link to="/profile">Мой профиль</router-link>
                  </li>
                  <li class="btn">
                    <router-link to="/settings">Настройки</router-link>
                  </li>
                  <li class="btn">
                    <router-link @click.prevent="signOut" to="/">Выход</router-link>
                  </li>
                </ul>
              </div>

              <div class="header__right-coin">
                <span>
                  <img src="@/assets/images/header/money.png" alt="" />
                </span>
                {{}}
                {{ getGame.length ? summCoints : 0 }}
              </div>

              <div class="header__right-bomb">
                <span>
                  <img src="@/assets/images/header/bomb.png" alt="" />
                </span>
                {{ summShots }}
              </div>

              <router-link to="/cart">
                <button class="header__right-cart header__right-case">
                  <span>{{ user && user.cart ? user.cart.length : 0 }}</span>
                  <img src="@/assets/images/header/chest.png" alt="" />
                </button>
              </router-link>
            </div>

            <div class="header__right-items" v-else>
              <router-link to="/login" href="" class="header__auth">Вход</router-link>
              <router-link to="/register" href="">
                <Button>Регистрация</Button>
              </router-link>
              <router-link to="/cart" href="" class="header__right-cart">
                <span>{{ user && user.cart ? user.cart.length : 0 }}</span>
                <img src="@/assets/images/cart-icon.png" alt="" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="fixed-menu">
      <div class="container">
        <ul class="fixed-menu__spisok">
          <li class="btn" v-for="(item, id) in menu" :key="id" @click="html.classList.remove('mobile-menu-open')">
            <router-link :to="item.link">
              {{ item.title }}
              <img v-if="item.img" :src="require(`@/assets/images/${item.img}`)" alt="" />
            </router-link>
            <div class="fixed-menu__spisok-submenu" v-if="item.submenu">
          <li class="btn" v-for="(list, idx) in item.submenu" :key="idx">
            <router-link :to="list.link">{{ list.title }}</router-link>
          </li>
      </div>
      </li>
      </ul>
    </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import getSummCoins from "../../utils/getSummCoins";
import Button from "../UI/Button.vue";

export default {
  data() {
    return {
      html: document.querySelector("html"),
      menu: [
        {
          title: "Главная",
          link: "/",
        },
        {
          title: "Каталог",
          link: "/catalog",
        },
        {
          title: "О нас",
          link: "/fas",
        },
        {
          title: "Помощь",
          link: "/help",
        },
        {
          link: "/bonus",
          img: "header/bonus.svg",
          bonus: true,
        },
        {
          title: "Партнерство",
          link: "/partnership",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["user", "auth", "getProducts", "loadingUsers", "getGame"]),

    summShots() {
      return Object.values(this.user.arsenal || {}).reduce((acc, curr) => acc + curr, 0);
    },

    summCoints() {
      return getSummCoins(this.getGame);
    },
  },
  watch: {
    getGame() {
      this.summCoints;
    },

    loadingUsers() {
      if (!this.loadingUsers) this.getGames({ id: this.user.id });
    },
  },
  methods: {
    ...mapActions(["signOut", "fetchProducts", "getGames"]),

    changeMobileMenu() {
      this.html.classList.toggle("mobile-menu-open");
    },
  },
  mounted() {
    this.fetchProducts();
  },
  components: {
    Button,
  },
};
</script>
