import { checkVkUserLikes } from "@/utils/vkUtils";
import { checkYoutubeUserLikes } from "@/utils/youtubeUtils";
import { getDatabase, get, set, ref, child } from "firebase/database";
import {checkOkUserLikes} from "@/utils/okUtils";
import {checkInstagramUserLikes} from "@/utils/instagramUtils";

export async function checkUserLikes(user, dispatch, commit) {
    const database = getDatabase();
    const postsSnapshot = await get(ref(database, 'products'));
    const posts = postsSnapshot.val().reduce((acc, curr) => {
        if (curr.posts) {
            Object.entries(curr.posts).forEach(([key, posts]) => {
                if (!acc[key]) {
                    acc[key] = new Map();
                }
                posts.forEach(post => {
                    acc[key].set(
                        post.toString(),
                        {
                            productId: curr.id,
                            liked: (user.liked || []).includes(post.toString()),
                        }
                    )
                })
            });
        }
        return acc;
    }, {});
    if (posts.youtube && user.social?.youtube?.accessToken) {
        await checkYoutubeUserLikes(posts.youtube, user, dispatch);
    }
    if (posts.vk && user.social?.vk?.accessToken) {
        await checkVkUserLikes(posts.vk, user, dispatch);
    }
    if (posts.ok && user.social?.ok?.accessToken) {
        await checkOkUserLikes(posts.ok, user, dispatch);
    }
    if (posts.instagram && user.social?.instagram?.accessToken) {
        await checkInstagramUserLikes(posts.instagram, user, dispatch);
    }
    const dateNew = await get(child(ref(getDatabase()), `users/${user.id}`));
    const userNew = dateNew.val();
    await commit('setUser', userNew);
}