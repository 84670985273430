<template>
  <div id="app">
    <div class="main" :style="{ backgroundImage: `url(${require('@/assets/images/main-bg.jpg')})` }">
      <div class="main-block">
        <router-view />
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Global/Footer.vue";

export default {
  components: {
    Footer,
  },
};
</script>
