import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import randomId from "../../utils/randomId";

export default {
    actions: {
        async uploadImage({ commit }, file) {
            if (file == '') return ''

            const randomName = randomId(12)
            const storage = getStorage();

            await uploadBytes(ref(storage, `${randomName}`), file)
            return await getDownloadURL(ref(storage, `${randomName}`))
        }
    },
}