const getSummCoins = (games) => {
    let summ = 0;
    games.forEach((game) => {
        if (game.getsCoints) {
            summ += game.getsCoints.reduce((prev, next) => prev + next.awards, 0);
        }
    });
    return summ;
}

export default getSummCoins