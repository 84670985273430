import axios from "axios";
import {getDatabase} from "firebase/database";
import isDev from "@/utils/checkDev";

export function getInstagramAccessToken(code) {
    return axios.post(`${process.env.VUE_APP_API}/instagram/access_token`, {
        client_id: process.env.VUE_APP_INSTAGRAM_CLIENT_ID,
        client_secret: process.env.VUE_APP_INSTAGRAM_CLIENT_SECRET,
        code,
        grant_type: 'authorization_code',
        redirect_uri: `${isDev() ? 'https://redirectmeto.com/' : ''}${window.location.origin}/social/instagram`,
    });
}

export async function checkInstagramUserLikes(postsList, user, dispatch) {
    const database = getDatabase();
    const postsArray = [...postsList.keys()] || [];

    const requests = postsArray.map(postId =>
        axios.get(`${process.env.VUE_APP_API}/instagram/comments/${postId}`)
    );

    try {
        const responses = await Promise.all(requests);
        console.log(responses);
    } catch (err) {
        if (err.response.status === 401) {
            dispatch('disconnectSocial', { type: 'instagram', reconnect: true })
        }
    }
}

