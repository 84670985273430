import { getDatabase, get, set, ref, child } from "firebase/database";
import {checkUserLikes} from "@/utils/checkUserLikes";

export default {
    state: {
        user: {},
        auth: true,
        loadingUsers: true
    },
    mutations: {
        setUser(state, date) {
            state.user = date
            state.auth = true
        },
        setAuth(state) {
            state.auth = false
        },
        setLoading(state, date) {
            state.loadingUsers = date
        }
    },
    actions: {
        async getUser({ commit, dispatch, state }, id) {
            if (id) {
                try {
                    const date = await get(child(ref(getDatabase()), `users/${id}`))
                    const user = date.val();
                    if (user) {
                        await commit('setUser', user)
                        await checkUserLikes(user, dispatch, commit)
                        if (state.loadingUsers) commit('setLoading', false)
                        return true
                    }
                } catch (e) {
                    console.log(e.message)
                    commit('setAuth')
                }
            } else {
                commit('setAuth')
                return false
            }
        },
        async changeUser({ commit }, user) {
            await set(ref(getDatabase(), `users/${user.id}`), { ...user })
            commit('setUser', user)
            alert('Профиль сохранен!')
        },
        async addCart({ commit, state }, { item, user }) {
            try {
                let cartArray = state.user.cart,
                    el = ''

                if (cartArray) {
                    el = cartArray.filter(obg => obg.id === item.id)
                    el.length != 0 ? alert("Товар уже добавлен!") : cartArray.push(item)
                } else {
                    state.user.cart = [item]
                }

                if (el.length == 0) {
                    await set(ref(getDatabase(), `users/${user.id}`), { ...user })
                    alert('Товар добавлен!')
                    commit('setUser', user)
                }
            } catch (e) {
                console.log(e.message)
            }
        },
        async deleteCart({ commit, state }, id) {
            try {
                state.user.cart = id ? state.user.cart.filter(item => item.id !== id) : []
                await set(ref(getDatabase(), `users/${state.user.id}`), { ...state.user })
            } catch (e) {
                console.log(e.message)
            }
        },
    },
    getters: {
        user(state) {
            return state.user
        },
        auth(state) {
            return state.auth
        },
        loadingUsers(state) {
            return state.loadingUsers
        }
    },
}
