<template>
  <section class="banner">
    <!-- <div class="banner__animation">
      <div class="banner__palm">
        <img src="@/assets/images/banner/palm-1.png" alt="" />
      </div>
      <div class="banner__palm">
        <img src="@/assets/images/banner/palm-2.png" alt="" />
      </div>
    </div>
    <div class="banner__pirate">
      <img src="@/assets/images/banner/pirate.png" alt="" />
    </div> -->
    <router-link to="/" class="header__logo">
      <span>Monetization Time</span>
      <!-- <img src="@/assets/images/banner/title.svg" alt="" /> -->
    </router-link>
    <router-link to="/catalog" class="banner__btn">
      <span> Перейти в каталог</span>
    </router-link>
    <a href="#content" class="banner__steering">
      <div ref="steering" class="banner__steering-items">
      </div>
    </a>
    <img src="@/assets/images/banner/bird1.png" alt="" style="position: absolute; left: 1131px; top: 308px" />
    <img src="@/assets/images/banner/bird2.png" alt="" style="position: absolute; left: 675px; top: 460px" />
    <img src="@/assets/images/banner/Piret.png" alt="" style="position: absolute; left: 0px; top: 308px" />
    <img src="@/assets/images/banner/Chest.png" alt="" style="position: absolute; right: 48px; top: 460px" />
  </section>
</template>

<script>
export default {
  mounted() {
    let rotate = 0;

    document.addEventListener("wheel", (evt) => {
      if (this.$refs.steering) {
        if (Math.sign(evt.deltaY) === 1) this.$refs.steering.style.transform = `rotate(${(rotate += 3)}deg)`;
        else this.$refs.steering.style.transform = `rotate(${(rotate -= 3)}deg)`;
      }
    });
  },
};
</script>
