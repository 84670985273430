import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, GoogleAuthProvider, signInWithPopup, FacebookAuthProvider, OAuthProvider, fetchSignInMethodsForEmail } from "firebase/auth";
import { getDatabase, get, set, update, ref, child } from "firebase/database";
import {getQuery} from "@/utils/requestUtils";
import isDev from "@/utils/checkDev";

export default {
    state: {},
    mutations: {},
    actions: {
        async fetchLogin({ commit, dispatch }, { email, password }) {
            try {
                await signInWithEmailAndPassword(getAuth(), email, password)
                alert('Авторизация прошла успешно!')
            } catch (e) {
                alert('Ошибка авторизации!')
                console.log(e.message)
            }
        },
        async fetchRegister({ commit, dispatch }, { form, email, password }) {
            try {
                const { user } = await createUserWithEmailAndPassword(getAuth(), email, password)
                const database = getDatabase()
                const social = {
                    vk: {
                        fullName: 'vk',
                        img: "item-1.png",
                        userId: '',
                        isLiked: false,
                        gotReward: false
                    }
                }

                form.date = form.date.toLocaleDateString("en-US")
                delete form.password
                delete form.password2

                await set(ref(database, `users/${user.uid}`), { ...form, coins: 0, gide: false, id: user.uid, social: social })
                dispatch('getUser', user.uid)
                alert('Регистрация прошла успешно!')
                return 'succefull'
            } catch (e) {
                alert('Неизвестная ошибка!')
            }
        },
        async signOut({ commit, dispatch }) {
            try {
                await signOut(getAuth())
                dispatch('getUser')
                alert('Успешно вышли из профиля!')
            } catch (e) {
                alert('Ошибка выхода!')
                console.log(e.message)
            }
        },
        async connectSocial({ commit, dispatch }, { type, immediate = false }) {
            switch (type) {
                case 'google':
                    console.log(gapi)
                    await gapi.client.setApiKey("AIzaSyB5bCa4xHbzIPLYYldBm6D5S6nwHNaeoBo");
                    const auth = await gapi.auth2.getAuthInstance()
                    await auth.signIn({ scope: "https://www.googleapis.com/auth/youtube.readonly" })
                    console.log("Sign-in successful")
                    break
                case 'vk':
                    const vkQueryParams= getQuery({
                        client_id: process.env.VUE_APP_VK_CLIENT_ID,
                        redirect_uri: `${window.location.origin}/social/vk`,
                        display: 'popup',
                        scope: 'offline',
                        response_type: 'token',
                        state: 'pirates-vk',
                    })
                    window.location.href=`https://oauth.vk.com/authorize?${vkQueryParams}`
                    break
                case 'ok':
                    const okQueryParams = getQuery({
                        client_id: process.env.VUE_APP_OK_CLIENT_ID,
                        redirect_uri: `${window.location.origin}/social/ok`,
                        scope: 'VALUABLE_ACCESS;LONG_ACCESS_TOKEN',
                        response_type: 'token',
                    })
                    window.location.href=`https://connect.ok.ru/oauth/authorize?${okQueryParams}`
                    break
                case 'youtube':
                    const youtubeQueryParams = getQuery({
                        client_id: process.env.VUE_APP_YOUTUBE_CLIENT_ID,
                        redirect_uri: `${window.location.origin}/social/youtube`,
                        response_type: 'token',
                        scope: 'https://www.googleapis.com/auth/youtube',
                        immediate,
                    })
                    window.location.href=`https://accounts.google.com/o/oauth2/v2/auth?${youtubeQueryParams}`
                    break
                case 'instagram':
                    const instagramQueryParams = getQuery({
                        client_id: process.env.VUE_APP_INSTAGRAM_CLIENT_ID,
                        redirect_uri: `${isDev() ? 'https://redirectmeto.com/' : ''}${window.location.origin}/social/instagram`,
                        scope: 'user_profile',
                        response_type: 'code',
                    })
                    window.location.href=`https://api.instagram.com/oauth/authorize?${instagramQueryParams}`
                    break
                default:
                    return
            }
        },
        async disconnectSocial({ commit, dispatch }, { type, reconnect = false }) {
            const database = getDatabase()
            getAuth().onAuthStateChanged(async (user) => {
                if (user) {
                    await update(ref(database, `users/${user.uid}/social/${type}`), { userSocialId: null, accessToken: null });
                    if (reconnect) {
                        switch (type) {
                            case 'youtube':
                                if (confirm('Авторизация YouTube истекла. Перезайти?')) {
                                    dispatch('connectSocial', { type: 'youtube', immediate: true });
                                } else {
                                    dispatch('disconnectSocial', { type: 'youtube' });
                                }
                                break;
                            case 'ok':
                                if (confirm('Авторизация Одноклассники истекла. Перезайти?')) {
                                    dispatch('connectSocial', { type: 'ok' });
                                } else {
                                    dispatch('disconnectSocial', { type: 'ok' });
                                }
                                break;
                            case 'vk':
                                if (confirm('Авторизация Вконтакте истекла. Перезайти?')) {
                                    dispatch('connectSocial', { type: 'vk' });
                                } else {
                                    dispatch('disconnectSocial', { type: 'vk' });
                                }
                                break;
                        }
                    }
                    dispatch('getUser', user.uid);
                } else {
                    this.$router.push("/");
                }
            })
        },
        async saveSocial({ commit, dispatch }, { type, ...data }) {
            const database = getDatabase()
            getAuth().onAuthStateChanged(async (user) => {
                if (user) {
                   let updateData = {};
                   switch (type) {
                       case 'vk':
                           updateData = {
                               userSocialId: data.userSocialId,
                               accessToken: data.accessToken,
                           }
                           break;
                       case 'youtube':
                           updateData = {
                               accessToken: data.accessToken,
                           }
                           break;
                       case 'ok':
                           updateData = {
                               sessionSecretKey: data.sessionSecretKey,
                               accessToken: data.accessToken,
                           }
                           break;
                       case 'instagram':
                           updateData = {
                               userSocialId: data.userSocialId,
                               accessToken: data.accessToken,
                           }
                   }
                    if (updateData?.accessToken) {
                        await update(ref(database, `users/${user.uid}/social/${type}`), updateData);
                        dispatch('getUser', user.uid);
                    }
                } else {
                    this.$router.push("/");
                }
            })
        }
    },
    getters: {},
}
