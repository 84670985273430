import {getQuery} from "@/utils/requestUtils";
import {getDatabase, ref, update} from "firebase/database";

export async function checkVkUserLikes(postsList, user, dispatch) {
    const database = getDatabase();
    const postsArray = [...postsList.keys()] || [];
    const requests = postsArray.map(postId => {
        const funcId = postId.replace('-', '_');
        const [owner_id, item_id] = postId.split('_');
        const queryStr = getQuery({
            user_id: user.social.vk.userSocialId,
            type: 'post',
            owner_id,
            item_id,
            v: '5.131',
            access_token: user.social.vk.accessToken,
            callback: `vkCallbackFunc_${funcId}`
        });

        return jsonpRequest(funcId, queryStr);
    })

    try {
        const responses = await Promise.all(requests);
        if (responses && responses[0]?.error?.error_code === 5) {
            dispatch('disconnectSocial', { type: 'vk', reconnect: true })
        } else {
            const result = responses.reduce((acc, item, index) => {
                const itemData = postsList.get(postsArray[index]);
                if (item?.response?.liked === 1 && itemData && itemData.liked === false) {
                    acc.liked.push(postsArray[index]);
                    if (!acc.arsenal[itemData.productId]) {
                        acc.arsenal[itemData.productId] = 1;
                    } else {
                        acc.arsenal[itemData.productId]++;
                    }
                }
                return acc;
            }, {
                liked: user.liked || [],
                arsenal: user.arsenal || {},
            });
            await update(ref(database, `users/${user.id}`), result);
        }
    } catch (err) {
        if (err.response.status === 401) {
            dispatch('disconnectSocial', { type: 'vk', reconnect: true })
        }
    }
}

export function jsonpRequest(funcId, queryStr) {
    return new Promise((resolve, reject) => {
        try {
            window[`vkCallbackFunc_${funcId}`] = (res) => {
                resolve(res);
                if (window[`vkCallbackFunc_${funcId}`]) {
                    window[`vkCallbackFunc_${funcId}`] = undefined;
                }
            };
            const script = document.createElement('script');
            script.src = `https://api.vk.com/method/likes.isLiked?${queryStr}`;
            document.querySelector('head').appendChild(script);
        } catch (err) {
            reject(err);
        }
    })
}
