import {getDatabase, ref, update} from "firebase/database";
import {getQuery} from "@/utils/requestUtils";
import axios from "axios";

export async function checkOkUserLikes(postsList, user, dispatch) {
    const database = getDatabase();
    const queryStr = getQuery({
        topic_ids: [...postsList.keys()].join(','),
        application_key: process.env.VUE_APP_OK_APP_KEY,
        fields: 'media_topic.like_summary,media_topic.id',
        format: 'json',
        method: 'mediatopic.getByIds',
        access_token: user.social.ok.accessToken,
        media_limit: 1,
    });

    try {
        const response = await axios.get(`https://api.ok.ru/fb.do?${queryStr}`);
        if ([102, 103, 104].includes(Number(response?.data?.error_code))) {
            dispatch('disconnectSocial', { type: 'ok', reconnect: true });
            return;
        }
        if (response?.data?.media_topics) {
            const result = response?.data?.media_topics.reduce((acc, item) => {
                const itemData = postsList.get(item.id);
                if (item.like_summary.self === true && itemData && itemData.liked === false) {
                    acc.liked.push(item.id);
                    if (!acc.arsenal[itemData.productId]) {
                        acc.arsenal[itemData.productId] = 1;
                    } else {
                        acc.arsenal[itemData.productId]++;
                    }
                }
                return acc;
            }, {
                liked: user.liked || [],
                arsenal: user.arsenal || {},
            });
            await update(ref(database, `users/${user.id}`), result);
        }
    } catch (err) {
        if (err.response.status === 401) {
            dispatch('disconnectSocial', { type: 'ok', reconnect: true })
        }
    }
}