import { getDatabase, get, set, ref, child } from "firebase/database";
import getCurrentDateTime from "../../utils/getCurrentDateTime";
import randomId from "../../utils/randomId";

export default {
    state: {
        loading: true,
        games: [],
        idGame: null,
        gameShips: []
    },
    mutations: {
        setGames(state, date) {
            state.games = date
        },
        setOpenGame(state, date) {
            state.idGame = date
        },
        setGameShips(state, date) {
            state.gameShips = date
        },
    },
    actions: {
        async getGames({ commit, state }, { id }) {
            if (id) {
                try {
                    const date = await get(child(ref(getDatabase()), `users/${id}/games`))
                    await commit('setGames', date.val() && date.val().length ? date.val() : [])
                    if (state.loading) state.loading = false
                } catch (e) {
                    console.log(e.message)
                }
            }
        },
        async getGameShips({ commit, state, dispatch }, { id, user }) {
            if (state.loading) await dispatch('getGames', { id: user.id })
            const obg = await dispatch('checkCurrentGame', { idProduct: id })
            commit('setGameShips', obg.length ? obg[0] : [])
        },
        async checkCurrentGame({ commit, state }, { idProduct }) {
            state.idGame = null
            return await state.games.filter((item, id) => {
                if (item.idProduct == idProduct) {
                    commit('setOpenGame', id)
                    return item
                }
            })
        },
        async changeGame({ commit, state, dispatch }, { title, user, idProduct, ships, extraction, battle, hits, getsCoints, allMissesHits }) {
            await dispatch('checkCurrentGame', { idProduct })
            if (state.idGame === null) {
                state.games.push({
                    title,
                    idProduct,
                    date: getCurrentDateTime(),
                    status: 'game',
                    extraction: 0,
                })
            } else if (ships) {
                const obg = state.games[state.idGame]
                state.games[state.idGame] = { ...obg, ships, extraction, battle, hits, getsCoints, allMissesHits }
            }
            await set(ref(getDatabase(), `users/${user.id}/games`), { ...state.games })
        },
        async endGame({ state, dispatch }, { user, idProduct }) {
            try {
                await dispatch('checkCurrentGame', { idProduct })
                state.games[state.idGame] = { ...state.games[state.idGame], idProduct: randomId(12), status: 'end' }
                await set(ref(getDatabase(), `users/${user.id}/games/${state.idGame}`), { ...state.games[state.idGame] })
                state.idGame = null
                alert('Игра завершена!')
            } catch (e) {
                console.log(e.message)
            }
        }
    },
    getters: {
        getGame(state) {
            return state.games
        },
        getShipGame(state) {
            return state.gameShips
        }
    },
}