import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { initializeApp } from "firebase/app";
import PrimeVue from 'primevue/config';

import "swiper/css";
import "swiper/css/pagination";

import 'simplebar';
import 'simplebar/dist/simplebar.css';

import "primevue/resources/themes/lara-light-indigo/theme.css";
import "primevue/resources/primevue.min.css";

initializeApp({
    apiKey: "AIzaSyC0x6Riy84miT5gqNMm-mtOTCI5Nv0eFvA",
    authDomain: "pirates-2a8b3.firebaseapp.com",
    databaseURL: "https://pirates-2a8b3-default-rtdb.firebaseio.com",
    projectId: "pirates-2a8b3",
    storageBucket: "pirates-2a8b3.appspot.com",
    messagingSenderId: "714236918933",
    appId: "1:714236918933:web:f13109c05ac8ca0c93af9c"
})

createApp(App).use(store).use(router).use(PrimeVue, {
    locale: {
        dayNames: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
        dayNamesShort: ["Вск", "Пнд", "Втр", "Срд", "Чтв", "Птн", "Сбт"],
        dayNamesMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
        monthNames: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
        monthNamesShort: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авн", "Сен", "Окт", "Ноя", "Дек"],
        today: 'Сегодня',
        weekHeader: 'Нед.',
        firstDayOfWeek: 0,
    }
}).mount("#app");
