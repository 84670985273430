<template>
  <Header />

  <div class="main">
    <div class="home__content-fixed"></div>
    <div class="main-block">
      <router-view />
    </div>
  </div>

  <Footer />
</template>

<script>
import Header from "../components/Global/Header.vue";
import Footer from "../components/Global/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  mounted() {
    const footer = document.querySelector(".footer"),
      homeFixed = document.querySelector(".home__content-fixed");

    function callback(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) homeFixed.style.position = "absolute";
        else homeFixed.style.position = "fixed";
      });
    }

    let observer = new IntersectionObserver(callback);
    observer.observe(footer);
  },
};
</script>
