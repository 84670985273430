import { createStore } from 'vuex'
import products from './modules/products'
import auth from './modules/auth'
import user from './modules/user'
import file from './modules/file'
import game from './modules/game'

export default createStore({
  modules: {
    products, auth, file, user, game
  }
})
