<template>
  <div id="app" class="header-min">
    <Header />

    <div class="main">
      <div class="main-block">
        <router-view />
      </div>
      <div class="container game-container">
        <div class="game__img"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Global/Header.vue";

export default {
  components: {
    Header,
  },
};
</script>

<style scoped>
.main {
  background-image: url(@/assets/images/banner/bg-2.jpg);
  background-color: rgba(0, 0, 0, 0.8);
  background-blend-mode: color;
  background-size: cover;
  background-position: top center;
}
</style>