<template>
  <div id="app" class="profile-main">
    <Header />

    <Banner />

    <section class="profile">
      <div class="container">
        <router-view />
      </div>
    </section>
  </div>
</template>

<script>
import Header from "../components/Global/Header.vue";
import Banner from "../components/Global/Banner.vue";

export default {
  components: {
    Header,
    Banner,
  },
};
</script>
