import { createRouter, createWebHistory } from "vue-router";

import "../assets/style/style.scss";

import main from "../layouts/main";
import min from "../layouts/minLayout";
import game from "../layouts/gameLayout";
import profile from "../layouts/profileLayout";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    meta: {
      layout: main,
    },
  },
  {
    path: "/game/:id",
    name: "Game",
    component: () => import("../views/Game.vue"),
    meta: {
      layout: game,
    },
  },
  {
    path: "/catalog",
    name: "Catalog",
    component: () => import("../views/Catalog.vue"),
    meta: {
      layout: game,
    },
  },
  {
    path: "/product/:id",
    name: "Product",
    component: () => import("../views/Product.vue"),
    meta: {
      layout: main,
    },
  },
  {
    path: "/detailNews",
    name: "DetailNews",
    component: () => import("../views/DetailNews.vue"),
    meta: {
      layout: main,
    },
  },
  {
    path: "/new/:id",
    name: "New",
    component: () => import("../views/New.vue"),
    meta: {
      layout: main,
    },
  },
  {
    path: "/actions",
    name: "Actions",
    component: () => import("../views/Actions.vue"),
    meta: {
      layout: main,
    },
  },
  {
    path: "/action/:id",
    name: "Action",
    component: () => import("../views/Action.vue"),
    meta: {
      layout: main,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/Profile.vue"),
    meta: {
      layout: main,
    },
  },
  {
    path: "/social/:type",
    name: "Social",
    component: () => import("../views/Social.vue"),
    meta: {
      layout: profile,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("../views/Settings.vue"),
    meta: {
      layout: profile,
    },
  },
  {
    path: "/cart",
    name: "Cart",
    component: () => import("../views/Cart.vue"),
    meta: {
      layout: main,
    },
  },
  {
    path: "/delivery",
    name: "Delivery",
    component: () => import("../views/Delivery.vue"),
    meta: {
      layout: main,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: {
      layout: main,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register.vue"),
    meta: {
      layout: main,
    },
  },
  {
    path: "/restore",
    name: "Restore",
    component: () => import("../views/Restore.vue"),
    meta: {
      layout: main,
    },
  },
  {
    path: "/fas",
    name: "About",
    component: () => import("../views/About.vue"),
    meta: {
      layout: main,
    },
  },
  {
    path: "/help",
    name: "Help",
    component: () => import("../views/Help.vue"),
    meta: {
      layout: main,
    },
  },
  {
    path: "/bonus",
    name: "Bonus",
    component: () => import("../views/Bonus.vue"),
    meta: {
      layout: main,
    },
  },
  {
    path: "/partnership",
    name: "Partnership",
    component: () => import("../views/Partnership.vue"),
    meta: {
      layout: main,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("../views/Error.vue"),
    meta: {
      layout: min,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

export default router;
