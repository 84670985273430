import axios from "axios";
import {getQuery} from "@/utils/requestUtils";
import {ref, update, get, getDatabase} from "firebase/database";
import isDev from "@/utils/checkDev";

export async function checkYoutubeUserLikes(videosList, user, dispatch) {
    const database = getDatabase();
    const queryStr = getQuery({
        id: [...videosList.keys()].join(','),
    });

    try {
        const response = await axios.get(`${isDev() ? '' : 'https://www.googleapis.com'}/youtube/v3/videos/getRating?${queryStr}`, {
            headers: {
                Authorization: `Bearer ${user.social.youtube.accessToken}`,
            },
        })
        if (response?.data?.items) {
            const result = response.data.items.reduce((acc, item) => {
                const itemData = videosList.get(item.videoId);
                if (item.rating === 'like' && itemData && itemData.liked === false) {
                    acc.liked.push(item.videoId);
                    if (!acc.arsenal[itemData.productId]) {
                        acc.arsenal[itemData.productId] = 1;
                    } else {
                        acc.arsenal[itemData.productId]++;
                    }
                }
                return acc;
            }, {
                liked: user.liked || [],
                arsenal: user.arsenal || {},
            });
            await update(ref(database, `users/${user.id}`), result);
        }
    } catch (err) {
        if (err.response.status === 401) {
            dispatch('disconnectSocial', { type: 'youtube', reconnect: true })
        }
    }
}