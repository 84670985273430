import qs from "qs";
export function getQuery(obj) {
    return qs.stringify(obj, {
        encodeValuesOnly: true,
    })
}

export function getParamsObj (url) {
    let s = url.split('#')
    if (s.length < 2 || s[1] === '') return s[0]
    let params = s[1]
    params = params.split('&')
    let res = {}
    params.forEach(el => {
        let val = el.split('=')
        if (val.length < 2) res[val[0]] = ''
        else res[val[0]] = val[1]
    })
    return res
}

export function getSearchParamsObj (url) {
    const urlSearchParams = new URLSearchParams(url);
    return Object.fromEntries(urlSearchParams.entries());
}
