<template>
  <component :is="$route.meta.layout"> </component>
  <Loader class="show" />
</template>

<script>
import Loader from "./components/Loader.vue";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      html: document.querySelector("html"),
    };
  },
  methods: {
    ...mapActions(["getUser", "fetchCategory"]),

    changeLoader() {
      this.html.classList.add("loading");
      setTimeout(() => {
        this.html.classList.remove("loading");
      }, 1500);
    },
  },
  mounted() {
    this.changeLoader();
    this.fetchCategory();
    onAuthStateChanged(getAuth(), (user) => {
      if (user) this.getUser(user.uid);
      else this.getUser();
    });
  },
  components: {
    Loader,
  },
};
</script>
